<template>
    <div class="d-flex">
        <button class="btn btn-outline-primary btn-sm  ms-auto" @click="clear">{{ $t('Clear') }}</button>
        <button class="btn btn-primary btn-sm  ms-2" @click="apply">{{ $t('Apply') }}</button>
    </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(['filterObject', 'columnName', 'filterItem', 'dropdown']);

const clear = () => {
    //clear item
    props.filterItem.clear();

    props.filterObject.apply();

    if (props.dropdown) {
        props.dropdown.close();
    }
}

const apply = () => {
    props.filterObject.apply();
    if (props.dropdown) {
        props.dropdown.close();
    }
}

</script>
